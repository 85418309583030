@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");

$font-primary: "Roboto Condensed", sans-serif;
$color-primary: #b6142c;
$color-dark: #212121;
$transition: 0.3s;
$text: 18px;
$heading: 30px;
*,
::before,
::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: $color-primary;
}
::-webkit-scrollbar-thumb:hover {
  background: #000;
}
body.chakra-ui-dark {
  .select_drop * {
    // background-color: var(--chakra-colors-gray-700);
    color: #000;
    box-shadow: none;
  }
}
.filter_customers {
  margin-bottom: 20px;
  > button {
    margin-right: 10px;
  }
}

.single_customer_sidepanel {
  .cus_details {
    margin-top: 10px;
    > h2 {
      font-size: 14px;
      color: var(--chakra-colors-gray-400);
      font-weight: 500;
    }
  }
  .detail_panel {
    padding: 15px;
    font-size: 12px;
  }
}

.create_payment_modal {
  div[role="group"] {
    margin-bottom: 10px;
    * {
      font-size: 14px;
    }
  }
}

.action_menu {
  h2 {
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 10px;
    margin: 10px 0;
  }
}

.sweet-alert.alert_pop {
  font-family: system-ui, sans-serif;
  h2 {
    text-transform: capitalize !important;
  }
  a.btn-primary {
    background-color: $color-dark;
    border-radius: 5px;
    width: 100px;
    color: #fff;
    text-decoration: none;
    line-height: 2.5;
    font-size: $text;
    transition: $transition;
  }
  &:hover {
    transition: $transition;
  }
}

.chakra-ui-dark {
  .sweet-alert.alert_pop,
  .sweet-alert.alert_pop div:first-child,
  .sweet-alert.alert_pop div:last-child,
  .sweet-alert.alert_pop span + div + div {
    background-color: var(--chakra-colors-gray-800) !important;
    a.btn.btn-lg.btn-primary {
      color: $color-primary;
      background-color: #fff;
    }
  }
}

a.anchor_hover {
  transition: $transition;
  border-radius: 8px;
  padding: 0 10px;
  &:hover {
    background-color: #f4f3f3;
    transition: $transition;
  }
}

body.chakra-ui-dark a.anchor_hover {
  &:hover {
    background-color: var(--chakra-colors-gray-800);
  }
}

.file-drop {
  width: 100%;
}

.file-drop > .file-drop-target {
  height: 190px;
  border: 3px dashed;
  border-color: var(--chakra-colors-gray-300);
  border-radius: 8px;
  color: var(--chakra-colors-black);
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;

  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: var(--chakra-colors-blue-300);
  box-shadow: none;
  z-index: 50;
  opacity: 0.7;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #fff;
}

.customer_info_accord * {
  padding: 0;
}

// -------------- MEDIA QURIES

@media only screen and (max-width: 767px) {
  .filter_customers {
    flex-wrap: wrap;
    gap: 10px;
  }

  .filter_customers > button {
    width: 100%;
    text-align: start;
  }
  .title_listing {
    font-size: 20px !important;
  }
}
